.cc-vod-pkg-root {
    margin-top: 110px;
    padding: 110px 20px 10px 10px;
    display: block;
    justify-content: center;
    background-color: black;
    color: whitesmoke;
}

.cc-vod-pkg-main {
    margin-top: 110px;
    padding: 110px 10px 10px 10px;
    display: flex;
    justify-content: center;
    background-color: black;
    color: whitesmoke;
}

.cc-vod-pkg-video-container {
    position: relative;
    padding: 10px 10px 10px 10px;
    display: block;
    align-content: center;
    align-items: center;
}

.cc-vod-pkg-video-container iframe, 
.cc-vod-pkg-video-container object, 
.cc--vod-pkg-video-container embed
{
position: relative;
top: 0;
left: 0;
padding-top: 10px;
min-height: 500px;
height: auto;
width: 90%;
}

.cc-vod-pkg-tile-heading {
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: underline;
    text-align: center;
}

@media only screen and (max-width: 1150px) {
    .cc-vod-pkg-root {
		margin-top: 80px;
        padding: 80px 20px 10px 20px;
	}
}

@media only screen and (max-width: 652px) {
    .cc-vod-pkg-video-container iframe, 
    .cc-vod-pkg-video-container object, 
    .cc--vod-pkg-video-container embed
    {
        min-height: 300px;
    }
}

.cc-vod-pkg-root strong {
    font-size: 1.2em;
}
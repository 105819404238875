.cc-nf-main {
    margin-top: 110px;
    padding: 110px 0 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cc-nf-main a {
    color: #59d3e3;
    text-decoration: none;
    font-weight: 900;
}

.cc-nf-main * {
    text-align: center;
}

@media only screen and (max-width: 1150px) {
    .cc-nf-main {
		margin-top: 80px;
        padding: 80px 10px 10px 10px;
	}
}
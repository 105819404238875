.cc-uf-main {
    margin-top: 110px;
    margin-left: 10px;
    padding: 110px 10px 10px 10px;
    display: block;
}

.cc-uf-main p, ul, li {
   padding: 10px; 
   text-align: left;
   margin-left: 10px;
}

@media only screen and (max-width: 1150px) {
    .cc-uf-main {
		margin-top: 80px;
        padding: 80px 10px 10px 10px;
	}
}
footer {
    margin-top: 100px;
}

.cc-info {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.cc-info * {
    margin: 0 10px;
}

.cc-info a {
    text-decoration: none;
    color: #59d3e3;
    font-size: 20px;
}

.cc-info img {
    border-radius: 15px;
}

.credits {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}
.cc-nav {
    display: flex;
    background-color: black;
    position: fixed;
    z-index: 10;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
	top: 0;
}

.slogan {
	display: block;
}

.logoImage {
	height:150px;	
	width:150px;
}

.logo {
    letter-spacing: 1px;
    font-weight: 900;
    color: whitesmoke;
    text-decoration: none;
}

.cc-nav-links {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

.cc-nav-links * {
    color: white;
    text-decoration: none;
    list-style: none;
    font-size: 20px;
}

.menu {
	display: none;
}

.menu div {
	width: 25px;
	height: 3px;
	background-color: whitesmoke;
	border-radius: 10px;
	margin: 5px;
	transition: all 0.3s ease;
}

@media screen and (max-width: 1150px) {

    body {
        overflow-x: hidden;
    }

	.slogan {
		display: none;
	}

	.logoImage {
		height:50px;	
		width:50px;
	}

	.cc-nav-links {
		position: absolute;
		right: 0px;
		height: calc(100vh - 100%);
		top: 100%;
		background: black;
		flex-direction: column;
		align-items: center;
		width: 100%;
		transform: translateX(100%);
		transition: transform 0.5s ease-in;
		font-size: .1rem;
	}

	.menu {
		display: block;
		cursor: pointer;
	}
}

.nav-active {
	transform: translateX(0%);
}

.toggle .line1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	transform: rotate(45deg) translate(-5px, -6px);
}

.toggle .line4 {
	transform: rotate(45deg) translate(-5px, -6px);
}

.toggle .line5 {
	transform: rotate(45deg) translate(-5px, -6px);
}
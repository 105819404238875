.cc-ev-main {
    display: flex;
    flex-direction: column;
    background-color: white !important;
    color: black !important;
}

.cc-loading {
    font-size: 20px;
    align-self: center;
}
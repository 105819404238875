.cc-rf-main {
    margin-top: 110px;
    padding: 110px 10px 10px 10px;
    display: block;
}

.cc-rf-main p {
   padding: 20px; 
   text-align: left;
}

.cc-rf-main h1 {
    text-align: center;
}

@media only screen and (max-width: 1150px) {
    .cc-rf-main {
		margin-top: 80px;
        padding: 80px 10px 10px 10px;
	}
}
.cc-lv-root {
    margin-top: 110px;
    padding: 110px 20px 10px 10px;
    display: block;
    justify-content: center;
    background-color: black;
    color: whitesmoke;
}

.cc-lv-main {
    margin-top: 10px;
    padding: 10px 50px 10px 50px;
    display: block;
    justify-content: center;
    background-color: black;
    color: whitesmoke;
}

.cc-lv-video-container {
    position: relative;
    padding: 10px 10px 10px 10px;
    display: block;
    align-content: center;
    align-items: center;
}

.cc-lv-tile-heading {
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: underline;
    text-align: center;
}

.cc-lv-href {
    color: white;    
    text-decoration: none;
    animation: blink 1.5s infinite;
}

@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.cc-lv-href a:visited {
    color: white;    
}
.cc-lv-video-container iframe, 
.cc-lv-video-container object, 
.cc--lv-video-container embed
{
position: relative;
top: 0;
left: 0;
padding-top: 10px;
min-height: 500px;
height: auto;
width: 90%;
}

@media only screen and (max-width: 1150px) {
    .cc-lv-root {
		margin-top: 80px;
        padding: 80px 20px 10px 20px;
	}
}

@media only screen and (max-width: 652px) {
    .cc-lv-video-container iframe, 
    .cc-lv-video-container object, 
    .cc-lv-video-container embed
    {
        min-height: 300px;
    }
}

.cc-lv-root strong {
    font-size: 1.2em;
}

.cc-au-main {
    margin-top: 110px;
    padding: 110px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.cc-au-main * {
    text-align: left;
}

.cc-au-main div {
    padding: 40px 20px 0 20px; 
}

.cc-au-main ul {
    padding: 20px;
}

.cc-au-main h1 {
    justify-content: center;
}

@media only screen and (max-width: 1150px) {
    .cc-au-main {
		margin-top: 80px;
        padding: 80px 10px 10px 10px;
	}
}
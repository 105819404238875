.block {
	border-radius: 10px;
	overflow: hidden;
	margin: 10px;
	background: white;
	max-width: 42%;
	width: 42%;
	height: auto;
	padding: 0px 0px 0px 0px;
}

.block a {
	text-decoration: none;
}

.block img {
	margin: auto;
	display: block;
	max-width: 100%;
	width: 100%;
	height: auto;
	opacity: 0.8;
}

.name {
	z-index: 3;
	color: black;
	justify-content: center;
	font-size: 1em;
	display: flex;
	padding: 5px;
}


@media only screen and (max-width: 652px) {
    .name {
		font-size: .6em;
	}
}
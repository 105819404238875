.cc-body {
  background-color: black;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
}

.cc-video-container {
position: relative;
padding-bottom: 56.4%;
padding-top: 100px;
}

.cc-video-container iframe, 
.cc-video-container object, 
.cc-video-container embed
{
position: absolute;
top: 0;
left: 0;
padding-top: 10px;
width:100%;
height: 100%;
}

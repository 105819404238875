.cc-pv-main {
    margin-top: 110px;
    padding: 110px 10px 10px 10px;
    display: block;
    text-align: left;
    margin-left: 20px; margin-right: 20px;
}

.cc-pv-main * {
    text-align: left;
    margin-left: 20px; margin-right: 20px;
}

@media only screen and (max-width: 1150px) {
    .cc-pv-main {
		margin-top: 80px;
        padding: 80px 10px 10px 10px;
	}
}